// sidebar

@mixin box-shadow($value: 0px) {
  box-shadow: 0 24px 40px rgba(0, 0, 0, 0.07),
    0 10.8529px 24.1177px rgba(0, 0, 0, 0.0456112),
    0 4.50776px 10.0172px rgba(0, 0, 0, 0.035),
    0 1.63037px 3.62304px rgba(0, 0, 0, 0.0243888);
}

#main-outlet-wrapper {
  .sidebar-wrapper {
    margin: 30px 0 50px;
    background-color: var(--secondary);
    border-radius: 1em;
    top: calc(var(--header-offset) + 30px);
    height: calc(100vh - (var(--header-offset) + 80px));
    @include box-shadow;
  }
  .sidebar-footer-wrapper {
    background: var(--secondary);
  }
}

body.has-sidebar-page {
  #main-outlet-wrapper {
    padding-left: 8px;
  }
  #main-outlet {
    max-width: unset;
  }
}

.desktop-view .sidebar-footer-wrapper .sidebar-footer-container::before {
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(var(--secondary), 1)
  );
}
