.showcased-categories-sidebar .two-topic-list-sidebar {
  .topic-excerpt {
    display: none;
  }

  .btn-link.posts-map.badge-posts {
    display: block;
  }

  .two-topic-list-sidebar .topic-list .topic-list-item {
    height: unset;
  }
}

.showcased-categories-sidebar {
  .full-width .contents .topic-list {
    display: unset;
  }

  .two-topic-list-sidebar {
    .topic-excerpt {
      display: none;
    }

    .btn-link.posts-map.badge-posts {
      display: block;
    }

    .topic-list .topic-list-item {
      height: unset;
    }
  }
}
