html body.has-sidebar-page.has-full-page-chat {
  #main-outlet-wrapper {
    gap: 2em;
  }
  #main-outlet {
    padding-bottom: 0 !important;
  }
}

.full-page-chat {
  border: none !important;
}

.chat-channel {
  height: calc(100vh - (var(--header-offset) + 10em));
}

.c-navbar-container {
  border-radius: 1em 1em 0 0;
}

.chat-composer__wrapper {
  background: transparent;
}

.full-page-chat .chat-full-page-header {
  border-radius: 1em 1em 0 0;
}

.has-full-page-chat #main-outlet.wrap {
  margin-bottom: 30px;
  padding-bottom: 0px !important;
  .full-page-chat {
    grid-template-rows: calc(var(--full-page-chat-height) - 50px);
  }
}

@media screen and (max-width: 1366px) {
  .has-full-page-chat #main-outlet {
    width: 100%;
  }

  .has-full-page-chat:not(.discourse-sidebar) #main-outlet.wrap {
    max-width: 100% !important;
    width: 100% !important;
    border-radius: 0;
    margin: 0;
    padding: 0;
    .full-page-chat {
      grid-template-rows: var(--full-page-chat-height);
    }
  }
}
